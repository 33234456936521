export function ProductData() {
  return {
    code: '',
    category: new CategoryProd(),
    name: '',
    price: 0,
    provider_prod_code: new BankProd(),
    active: 1,
  }
}


export function CategoryProd() {
  return {
    _id: '',
    code: '',
    name: '',
  }
}

export function BankProd() {
  return {
    _id: '',
    code: '',
    name: '',
    user: '',
    password: '',
    secret_code: '',
    url: new Url(),
    from_date: '',
    to_date: '',
    active: 0,
    created_at: '',
    created_by: '',
    updated_at: '',
    updated_by: '',
  }
}

export function Url() {
  return {
    url: '',
    method: '',
    param: '',
    url_inq: '',
    url_pay: '',
    url_rev: '',
    url_adv: '',
  }
}

export function StatusList() {
  return [
    {text: 'Not Active', value: 0},
    {text: 'Active', value: 1},
    {text: 'Trouble', value: 2},
  ];
}
