<template>
  <v-card-text>
    <v-row>
      <v-col
          sm="6"
          cols="12"
      >
        <v-autocomplete
            dense
            v-model="product_model.category"
            label="Category"
            :items="cat_prod"
            item-text="name"
            item-value="code"
            clearable
            :rules="[v => !!v || 'Category must filled']"
            return-object
        ></v-autocomplete>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="product_model.code"
            label="Code"
            :rules="RequiredRules"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="product_model.name"
            sm="12"
            cols="6"
            label="Name"
            :rules="RequiredRules"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="product_model.price"
            sm="12"
            cols="6"
            label="Price"
            type="number"
            :rules="RequiredRules"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-autocomplete
            dense
            v-model="product_model.provider_prod_code"
            label="Provider"
            :items="bank_prod"
            item-text="name"
            item-value="code"
            clearable
            :rules="[v => !!v || 'Provider must filled']"
            return-object
        ></v-autocomplete>
      </v-col>
      <template v-if="update === '1'">
        <v-col
            sm="6"
            cols="12"
        >
          <v-autocomplete
            dense
            v-model="product_model.active"
            label="Status"
            :items="status_list"
            clearable
            :rules="[v => !!v || 'Status must filled']"
          ></v-autocomplete>
        </v-col>
      </template>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  name: "ProductFormComponent",
  data: () => ({
    RequiredRules: [
      v => !!v || 'Field cannot empty',
    ],
  }),
  props: {
    product_model: Object,
    cat_prod: [],
    bank_prod: [],
    status_list: [],
    update: String,
  }
}
</script>

<style scoped>

</style>
